const drawerWidth = 352;
const appBarHeight = 112;

export const styles = theme => ({
	root: {
		flexGrow: 1,
		display: 'flex',
		fontSize: '14px',
		fontFamily: ['Nunito Sans', 'sans-serif'],
	},
	grow: {
		flexGrow: 1,
	},
	h6: {
		fontFamily: ['Nunito Sans', 'sans-serif'],
		fontWeight: 900,
		margin: '8px 0'
	},
	dialogLabel: {
		fontFamily: ['Nunito Sans', 'sans-serif'],
		fontWeight: 700,
		margin: '8px 0',
		fontSize: '14px',
		color: '#5c5c5c'
	},
	offsetLabel: {
		padding: '32px 16px 10px'
	},
	listItem: {
		height: '48px',
		'& img': {
			width: '30px',
			height: '30px',
			objectFit: 'contain',
			marginRight: '8px',
		},
	},
	dialogRadio: {
		'& svg': {
			color: '#3c97f9'
		},
		'& input': {
			color: '#3c97f9'
		},
		'& span': {
			color: '#3c97f9'
		},
		color: '#3c97f9',
		padding: 0
	},
	dialogFooter: {
		position: 'fixed',
		bottom: 0,
		textAlign: 'center',
		width: "100%",
		background: "linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 70%)",
		filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 )",
	},
	dialogButton: {
		fontFamily: "Nunito Sans",
		fontSize: '14px',
		bottom: 0,
		backgroundColor: '#0770b2',
		color: '#fff',
		textTransform: 'none',
		height: '40px',
		padding: '8px 48px',
		margin: '15px 0',
		'&:hover': {
			backgroundColor: '#065a90'
		}
	},
	readMore: {
		color: '#5c5c5c',
		fontSize: '14px',
		textDecoration: 'none',
		'& img': {
			margin: '0 0 -1px 6px'
		}
	},
	UIField: {
		height: '36px',
		backgroundColor: '#fff',
		margin: '4px 6px 0 10px',
		fontSize: '14px !important',
		width: '300px',
		'&:focus': {
			borderColor: '#dcdcdc',
		},
		'& fieldset': {
			height: '42px',
			paddingLeft: '8px',
			border: '1px solid #dcdcdc',
			'&:hover': {
				border: '1px solid #dcdcdc',
			},
			'& legend': {
				display: 'none'
			}
		},
		'& input': {
			fontFamily: "Nunito Sans",
			color: '#5c5c5c',
			padding: '9px 12px 10px',
			fontSize: '14px !important',
		},
		'& input::placeholder': {
			fontFamily: "Nunito Sans",
			color: '#5c5c5c',
			padding: '10px 10px 10px 0',
			fontSize: '14px !important',
			opacity: 1
		},
		'& input::-webkit-input-placeholder': {
			padding: '0',
		},
		[theme.breakpoints.down('sm')]: {
			width: "100%",
			margin: '4px 10px 0 10px',
		}
	},
	cssFocused: {},
	notchedOutline: {},
	cssOutlinedInput: {
		'&$cssFocused $notchedOutline': {
			borderColor: '#dcdcdc',
			borderWidth: '1px'
		},
	},
	placeholder: {
		'&::placeholder' : {
			color: '#5c5c5c'
		},
		'&::-webkit-input-placeholder' : {
			color: '#5c5c5c'
		}
	},
	appBar: {
		height: appBarHeight,
		backgroundColor: '#fff',
		boxShadow: 'none',
	},
	toolBar: {
		minHeight: '55px',
		padding: 0
	},
	menuButton: {
		color: '#0770b2',
	},
	logo: {
		margin: '10px',
		'& a': {
			color: '#fff',
			textDecoration: 'none'
		},
		'& img': {
			height: '50px'
		},
	},
	secondaryToolbar: {
		backgroundColor: '#f9f9f9'
	},
	formControl: {
		margin: '8px 6px',
		minWidth: 120,
	},
	dialogBar: {
		backgroundColor: '#fff',
		color: '#5c5c5c',
		fontWeight: 700,
		fontSize: '14px',
		justifyContent: 'space-between',
		boxShadow: '0 8px 24px rgba(82, 82, 82, 0.08), 0 4px 12px rgba(82, 82, 82, 0.04)'
	},
	secondaryIcon: {
		color: "#0770b2",
		padding: 0
	},
	dialogToolbar: {
		justifyContent: 'space-between',
		display: 'flex'
	},
	dialogFormControl: {
		width: '100%',
		margin: '0 0 100px'
	},
	icon: {
		color: '#3c97f9',
		fontSize: 24,
		margin: '0 3px'
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	},
	drawerPaper: {
		width: drawerWidth,
		margin: appBarHeight + 'px 0 0'
	},
	drawerHeader: {
		display: 'flex',
		alignItems: 'center',
		padding: '0 8px',
		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	},
	content: {
		flexGrow: 1,
		marginTop: appBarHeight,
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	contentShift: {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	},
	newsFeedButton: {
		fontSize: '14px',
		textTransform: 'none',
		'& img': {
			marginRight: '8px'
		}
	},
	overrides: {
		MuiDivider: { // Name of the component ⚛️ / style sheet
			root: { // Name of the rule
				backgroundColor: '#cdcdcd', // Some CSS
			},
			backgroundColor: '#cdcdcd', // Some CSS
		},
	},
	newsFab: {
		position: 'absolute',
		bottom: theme.spacing(2),
		left: theme.spacing(3),
		zIndex: 1,
		backgroundColor: '#fff'
	},
	filterFab: {
		zIndex: 1,
		position: 'absolute',
		bottom: theme.spacing(2.7),
		left: '50%',
		width: '118px',
		height: '32px',
		backgroundColor: '#fff',
		'-webkit-transform': 'translateX(-50%)',
		transform: 'translateX(-50%)',
		justifyContent: 'normal',
		'& span': {
			display: 'inline',
			width: 'auto',
		}
	},
	dialogContent: {
		margin: '65px 0 0'
	},
	input: {
		display: 'none',
	}
});

export default styles
