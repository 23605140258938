import { mapInstance } from '../App';
import model from '../model/model';


var layerGPS = null;
var layer = null;
var coordMarkers = null;
export var m = null;
var centerMap = null;
var Loader = null;
var SMap;
var JAK;
var lastTimestamp = 0;
var newCoords = null;
var newZoom = null;
var locked = false;
var showRegions = true;

document.addEventListener("DOMContentLoaded", function() {

	loadScript('https://api.mapy.cz/loader.js', loaderLoad);
});

function loaderLoad() {
	Loader = window.Loader;
	Loader.async = true;
	Loader.load(null, {suggest: true}, createMap);
}

const createMap = function() {

	SMap = window.SMap;
	JAK = window.JAK;

	// Init map.
	centerMap = SMap.Coords.fromWGS84(15.3386478, 49.7437506);
	m = new SMap(JAK.gel("map"), centerMap, 8);
	m.addDefaultLayer(SMap.DEF_SMART_BASE).enable();

	m.addDefaultControls();

	var clusterer = new SMap.Marker.Clusterer(m);

	// Add Marker layer.
	layer = new SMap.Layer.Marker();
	m.addLayer(layer);
	layer.enable();

	// Add GPS layer.
	layerGPS = new SMap.Layer.Marker();
	layerGPS.setClusterer(clusterer);
	m.addLayer(layerGPS);
	layerGPS.enable();

	var signals = m.getSignals();
	signals.addListener(window, "*", getDirectivesOnMapChange);
	if (navigator.geolocation) {
		var geo_options = {
			enableHighAccuracy: true,
			maximumAge        : 600000,  // 10 minutes
			timeout           : 60000  // 1 minute
		};

		navigator.geolocation.getCurrentPosition(function (position) {
			printLocation(position, true);
		}, geoError, geo_options);
	} else {
		// alert("Váš přehlížeč nepodporuje geolokaci.");
	}

	// naseptavac
	let inputEl = document.querySelector("input[id='searchField']");
	let suggest = new SMap.Suggest(inputEl);
	suggest.addListener("suggest", suggestData => {
		centerMap = SMap.Coords.fromWGS84(suggestData.data.longitude, suggestData.data.latitude)
		m.setCenterZoom(centerMap, 12);
		sendDirectivesToReactInstance();
	});
}

function getDirectivesOnMapChange(e, geolocationChange) {
	if (e.type==='marker-click') {
		var markerContainer = e.target.getContainer();
		var markerNode = markerContainer[3];
		if (markerNode.getAttribute('class')==='clusterWrapper') {
			var nextZoom = (m.getZoom() <= 8) ? 9 : 12;
			if (nextZoom === 9) {
				nextZoom = 10;
			}
			m.setCenterZoom(e.target.getCoords(), nextZoom);
		}
	}
	/* no data fetching for these events as it doesn't contain updated coords&zoom + blocks any other event coming 1 second after */
	if (['map-focus', 'zoom-start', 'control-mouse-zoom', 'zoom-step'].indexOf(e.type)!==-1) {
		return;
	}
	if (e.type==='map-lock') {
		locked = true;
	}
	if (e.type==='map-unlock') {
		locked = false;
	}

	if (!locked) {
		var timestamp = e.timeStamp;
		if ((timestamp - lastTimestamp) > 1000) {
			sendDirectivesToReactInstance();
			lastTimestamp = timestamp;
		}
		else if (geolocationChange) {
			sendDirectivesToReactInstance(geolocationChange);
		}
	}
}

export function sendDirectivesToReactInstance(geolocationChange, geolocationZoomChange) {
	if (newCoords !== m.getCenter() || newZoom !== m.getZoom() || geolocationChange) {
		newCoords = m.getCenter();
		newZoom = m.getZoom();
		var directives = {"coords" : newCoords, "zoom" : newZoom};
		if (newZoom < 12 && geolocationChange !== true && geolocationZoomChange !== true) {
			showRegions = true;
		}
		else {
			showRegions = false;
		}
		mapInstance.changeDirectives(directives, geolocationChange, showRegions);
	}
}

function printLocation(position) {
	showRegions = false;
	layerGPS.removeAll();
	var long = position.coords.longitude;
	var lat = position.coords.latitude;
	var pozice = SMap.Coords.fromWGS84(long, lat);
	var markerIcon = JAK.mel("div", {}, {backgroundImage: "url('/images/gps.png')", backgroundSize: "contain", width: "35px", borderRadius: "100%", height: "35px", fontFamily: "fontAwesome", fontSize: "20px", color: "white", position: "relative"});
	markerIcon.innerHTML = "<span id='gps'></span>";
	var markerGps = new SMap.Marker(pozice, "gps", {url:markerIcon});
	layerGPS.addMarker(markerGps);

	var gps = SMap.Coords.fromWGS84(long, lat);
	coordMarkers = [];
	coordMarkers.push(gps);

	var coords = m.computeCenterZoom(coordMarkers);
	m.setCenter(coords[0]); // TBD

	if (layer)
	{
		let markers = new Array(layer.getMarkers().length);
		for (let i=0; i<layer.getMarkers().length; i++)
		{
			markers[i] = {
				distance: calculateGeoDistance(layer.getMarkers()[i].getCoords().y, layer.getMarkers()[i].getCoords().x, lat, long),
				marker: layer.getMarkers()[i],
			};
		}
		if (markers.length)
		{
			markers.sort(function(a, b){return a.distance - b.distance});
			var zoom = m.computeCenterZoom(markers.slice(0, 15).map((item) => {
				return item.marker.getCoords();
			}));
			if (zoom[1]>m.getZoom())
				m.setZoom(zoom[1], coords[0]);
		}
	}

	var time = {
		timeStamp: Date.now(),
	}
	getDirectivesOnMapChange(time, true);
}

export function addMarkerDataCallback(data) {
	addMarkerData(data);
}

/*
export function addEventsCountPerRegion(clustererData) {
	layer.removeAll();
	closeCardOnZoomOut();
	// Add markers for visible data
	var markers = [];
	Object.keys( clustererData ).forEach( key => {
		if (key !== 'zoom') {
			var item = clustererData[key];
			var point = SMap.Coords.fromWGS84(item.location__region_lng, item.location__region_lat);

			var znacka = JAK.mel("div", {className: "clusterWrapper"});
			var popisek = JAK.mel("div", {className: "cluster"}, {
				textAlign: "center",
				color: "white",
				fontWeight: "bold"
			});
			var label = JAK.mel("span", {});
			label.innerHTML = item.count;
			popisek.appendChild(label);
			znacka.appendChild(popisek);

			var marker = new SMap.Marker(point, null, {url: znacka});
			markers.push(marker);
		}
	});
	layer.addMarker(markers);
}
*/

/*
export function addEventsCountPerDistrict(clustererData) {
	layer.removeAll();
	closeCardOnZoomOut();
	// Add markers for visible data
	var markers = [];
	Object.keys( clustererData ).forEach( key => {
		if (key !== 'zoom') {
			var item = clustererData[key];
			var point = SMap.Coords.fromWGS84(item.location__district_lng, item.location__district_lat);

			var znacka = JAK.mel("div", {className: "clusterWrapper"});
			var popisek = JAK.mel("div", {className: "cluster"}, {
				textAlign: "center",
				color: "white",
				fontWeight: "bold"
			});
			var label = JAK.mel("span", {});
			label.innerHTML = item.count;
			popisek.appendChild(label);
			znacka.appendChild(popisek);

			var marker = new SMap.Marker(point, null, {url: znacka});
			markers.push(marker);
		}
	});
	layer.addMarker(markers);
}
*/

function addMarkerData(markerData) {
	layer.removeAll();
	// Add markers for visible data
	var markers = [];
	Object.keys( markerData ).forEach( key => {
		if (key !== 'zoom') {
			const dimensions = calculatePinDimensions();
			var item = markerData[key];
			var point = SMap.Coords.fromWGS84(item.long, item.lat);

			var znacka = JAK.mel("div", {}, {
				width: 50,
				height: dimensions[1],
			});
			var obrazek = JAK.mel("img", {
				src: item.pin,
			}, {
				width: 50,
				height: 70,
				display: 'block',
				margin: '0 auto',
				transform: 'scale('+dimensions[0]/50+')',
				transition: 'transform .5s ease-out',
			});
			znacka.appendChild(obrazek);

			var marker = new SMap.Marker(point, null, {
				url: znacka,
				size: [50, 70],
				anchor: {
					left: 25,
					bottom: 1
				},
			});

			var googleProxyLink = item.link;

			var card = new SMap.Card(312, {anchor: {top: -110, left: 8}});
			const czTimeString = model.getCzechDate(item.timestamp * 1000);
			card.getBody().innerHTML = `<div class="newsFeed-item newsfeed-item-tooltip">
					<div class="newsfeed-caption">
						<div class="category-label category-${item.category.key}">${item.category.name}</div>
						<span class="newsfeed-timestamp">${czTimeString}</span>
					</div>
					<h6>
						${item.title}
					</h6>
					<a href=${googleProxyLink} target="_blank" class="readMore">Číst více<img src="images/forwardArrow.png" /></a>
					<div class="tooltipTail"></div>
				</div>`;

			marker.decorate(SMap.Marker.Feature.Card, card, {anchor: {left: 30}});
			markers.push(marker);
		}
	});
	layer.addMarker(markers);
}

/*
function closeCardOnZoomOut() {
	if (document.getElementsByClassName('card').length && document.getElementsByClassName('close').length) {
		document.getElementsByClassName('close')[0].click()
	}
}
*/

function geoError() {
	showRegions = true;
	m.redraw();
	alert("Bohužel nemáte povolené polohové služby.\n" +
		"1, můžete je zapnout\n" +
		"2, hledat podle města");
}

function loadScript(url, callback){

	var script = document.createElement("script")
	script.type = "text/javascript";

	if (script.readyState){  //IE
		script.onreadystatechange = function(){
			if (script.readyState==='loaded' || script.readyState==='complete') {
				script.onreadystatechange = null;
				callback();
			}
		};
	} else {  //Others
		script.onload = function(){
			callback();
		};
	}

	script.src = url;
	document.getElementsByTagName("head")[0].appendChild(script);
}

function calculatePinDimensions()
{
	const w = Math.min(m.getZoom()*5, 50);

	return [w, (w*1.4)];
}

function calculateGeoDistance(lat1, lon1, lat2, lon2)
{
	var R = 6371; // km
	var dLat = toRad(lat2-lat1);
	var dLon = toRad(lon2-lon1);

	var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
	Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(toRad(lat1)) * Math.cos(toRad(lat2));
	var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
	var d = R * c;
	return d;
}

// Converts numeric degrees to radians
function toRad(Value)
{
	return Value * Math.PI / 180;
}
