import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { createTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import csLocale from 'date-fns/locale/cs';
import format from 'date-fns/format';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Route } from 'react-router-dom';
import TheMap from './components/TheMap';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select, { components } from 'react-select';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import BookmarkBorder from '@material-ui/icons/BookmarkBorder';
import Share from '@material-ui/icons/Share';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Slide from '@material-ui/core/Slide';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import styles from './App.styles';
import Fab from '@material-ui/core/Fab';

var mapInstance;

const TransitionFunction = (props, ref) => (
	<Slide direction='up' {...props} ref={ref} />
);
const Transition = React.forwardRef(TransitionFunction)

class CsLocalizedUtils extends DateFnsUtils {
	getCalendarHeaderText(date) {
	  return format(date, 'LLLL yyyy', {locale: this.locale});
	}

	getDatePickerHeaderText(date) {
	  return format(date, 'dd MMMM', {locale: this.locale});
	}
}

class App extends Component {
	constructor(props) {
		super(props);

		const filteredCategory = this.urlParamsSearch('filterCategory');

		this.state = {
			leftDrawerOpen: false,
			rightDrawerOpen: false,
			category: (filteredCategory!==null && (/^(\d+,?)+$/).test(filteredCategory) ? filteredCategory : 'all'),
			categories: [{ value: 'all', label: 'Všechny kategorie zpráv', image: 'images/category-all.svg' }],
			date: new Date(),
			newsFeed: [],
			dialogOpen: false,
			dialogHeader: '',
			dialogContent: [],
			newsDialogOpen: false
		};
	}

	urlParamsSearch = (key) => {
		const pairs = (window.location.search[0] === '?' ? window.location.search.substr(1) : window.location.search).split('&');

		for (let i = 0; i<pairs.length; i++)
		{
			let pair = pairs[i].split('=');
			if (pair[0]===key)
				return pair[1];
		}

		return null;
	}

	// ZISTIT preco bol v toggleSidebar m.syncport();
	toggleRightDrawer = () => {
		this.setState({'rightDrawerOpen': !this.state.rightDrawerOpen});
	};

	toggleLeftDrawer = (open) => {
		this.setState({'leftDrawerOpen': open});
	};

	handleClose = () => {
		this.setState({dialogOpen: false});
	}
	handleChange = event => {
		this.setState({ [event.target.name]: event.target.value });
	}
	selectCategory = ({value}) => {
		this.setState({ category: value})
	}
	selectDate = value => {
		this.setState({ date: value})
	}

	handleSubmit = () => {
		if (this.state._category) {
			this.setState({
				category: this.state._category,
			});
		}
		else {
			this.setState({
				category: this.state.category,
			});
		}

		this.setState({
			date: this.state.date,
		});

		this.handleClose();
	}

	showDialog = (contentType, header) => () => {
		this.setState({
			dialogOpen: true,
			dialogContentType: contentType,
			dialogHeader: header,
		});
	}

	componentDidMount = () => {
		// get category list with icons from drupal
		const self = this;
		fetch(process.env.REACT_APP_SERVICE_URL + '/map/categories')
			.then((resp) => resp.json())
			.then(data => {
				self.setState({
					categories: this.state.categories.concat(data.map(category => {
						return {
							value: ''+category.id,
							label: category.name,
							image: category.icon
						}
					}))
				});
			})

		fetch(process.env.REACT_APP_SERVICE_URL + '/map/latest-feed')
			.then((resp) => resp.json())
			.then(data => {
				self.setState({
					newsFeed: data
				});
			});
	}

	render() {
		const { classes } = this.props;
		const theme = createTheme({
			palette: {
				primary: {
					main: '#0770b2',
					dark: '#065a90'
				},
				secondary: {
					main: '#0770b2'
				}
			},
			overrides: {
				MuiOutlinedInput: {
					root: {
						'&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
							borderColor: '#dcdcdc',
							borderWidth: '1px',
							// Reset on touch devices, it doesn't add specificity
							'@media (hover: none)': {
								borderColor: '#dcdcdc',
							},
						},
					}
				},
			},
			typography: {
				useNextVariants: true,
			},
		});
		const customStyles = {
			option: (provided, state) => ({
				...provided,
				color: (state.isSelected) ? '#3c97f9':'#5c5c5c',
				fontFamily: "Nunito Sans",
				fontSize: 14,
				height: 40,
				alignItems: 'center',
				display: 'flex',
				alignContent: 'center',
				padding: '0 6px',
				backgroundColor: (state.isSelected) ? '#fff' : provided.backgroundColor,
				cursor: 'pointer',
				'& div.imgWrapper': {
					textAlign: 'center',
					width: '21px',
					'& img': {
						display: 'block',
						margin: '0 auto',
						maxHeight: '20px',
					}
				},
				'& div.categorySelectLabel': {
					width: 170,
					margin: '0px 0 0 10px',
					lineHeight: '24px',
				}
			}),
			control: (provided, state) => ({
				...provided,
				color: '#5c5c5c',
				fontSize: 14,
				width: '260px',
				border: (state.isFocused) ? '1px solid #dcdcdc' : '1px solid #dcdcdc',
				'&:hover': { borderColor: '1px solid #dcdcdc' },
				boxShadow: 'none'
			}),
			singleValue: (provided) => ({
				...provided,
				color: '#5c5c5c',
				fontSize: 14,
			}),
			indicatorSeparator: () => ({
				display: 'none'
			}),
			menuList: () => ({
				border: 0,
				maxHeight: '250px',
				overflow: 'auto'
			}),
			menu: (provided) => ({
				...provided,
				border: '1px solid #dcdcdc',
				borderRadius: '4px',
				backgroundColor: '#fff',
				width: 260,
				padding: '8px 5px',
				boxShadow: 0
			}),
			placeholder: (provided) => ({
				...provided,
				color: '#5c5c5c',
				fontSize: '14px',
			})
		}
		const getCzechDate = (timestamp) => {
			const czMonths = ['leden', 'únor', 'březen', 'duben', 'květen', 'červen', 'červenec', 'srpen', 'září', 'říjen', 'listopad', 'prosinec'];
			const date = new Date(timestamp * 1000);
			return date.getDate() + '. ' + czMonths[date.getMonth()] + ' ' + date.getFullYear();
		}
		const DropdownIndicator = (props) => {
			return components.DropdownIndicator && (
				<components.DropdownIndicator {...props}>
					<ArrowDropDown
					/>
				</components.DropdownIndicator>
			);
		};
		const DatePickerInput = (props) => {
			return <TextField
				className={classes.UIField}
				variant="outlined"
				placeholder="Vyberte datum"
				{...props}
				InputProps={{
					classes: {
						root: classes.cssOutlinedInput,
						focused: classes.cssFocused,
						notchedOutline: classes.notchedOutline,
						input: classes.placeholder
					},
				}}
			/>
		}
		const CustomOption = (props) => {
			return (
				<div>
					<components.Option {...props} >
						<div className="imgWrapper">
							<img src={props.data.image} alt={props.data.label} />
						</div>
						<div className="categorySelectLabel">
							{props.data.label}
						</div>
						{props.isSelected ? (
							<img src="./images/selected.png" alt="Vybráno" />
						): ''}
					</components.Option>
				</div>
			)
		}
		const categoriesSelect = this.state.categories.length ? (
			<FormControl className={classes.formControl}>
				<Select
					components={{DropdownIndicator, Option: CustomOption}}
					options={this.state.categories}
					name="categories"
					onChange={this.selectCategory}
					styles={customStyles}
					placeholder="Vyberte kategorii zpráv"
				/>
			</FormControl>
		) : '';
		const dateSelect = (
			<FormControl className={classes.formControl}>
				<MuiThemeProvider theme={theme}>
					<MuiPickersUtilsProvider utils={CsLocalizedUtils} locale={csLocale}>
						<DatePicker
							disableToolbar
							TextFieldComponent={DatePickerInput}
							inputVariant="outlined"
							variant="inline"
							format="dd. MMMM yyyy"
							autoOk={true}
							disableFuture={true}
							value={this.state.date}
							onChange={this.selectDate}
						/>
					</MuiPickersUtilsProvider>
				</MuiThemeProvider>
			</FormControl>
		);
		const NewsFeed = () => <div className="newsfeed">
			{ this.state.newsFeed.map((el, i) => (
				<div key={i} className="newsfeed-container">
					<div className="newsfeed-item">
						<div className="newsfeed-caption">
							{el.category.map((cat) => (<div className={ `category-label category-${cat.key}` } key={cat.key}>{cat.name}</div>))}
							<span className="newsfeed-timestamp">{getCzechDate(el.timestamp)}</span>
							<div className="socialWrapper">
								<BookmarkBorder className={classes.icon} />
								<Share className={classes.icon} />
							</div>
						</div>
						<Typography key={i} variant="h6" color="inherit" className={classes.h6}>
							<a href={el.link} className="title" rel="noopener noreferrer" target="_blank">{el.title}</a><br />
							<a href={el.link} className={classes.readMore} rel="noopener noreferrer" target="_blank">Číst více<img src="images/forwardArrow.png" alt="Číst více" /></a>
						</Typography>
					</div>
					<Divider />
				</div>
			))}
		</div>;

		return ( <CssBaseline>
			<div className="main-wrapper">
				<MuiThemeProvider theme={theme}>
					<div className={classes.root}>
						<Dialog
							fullScreen
							open={this.state.dialogOpen}
							onClose={this.handleClose}
							TransitionComponent={Transition}
						>
							<AppBar className={classes.dialogBar}>
								<Toolbar className={classes.dialogToolbar}>
									<Typography variant="h6" color="inherit" className={classNames(classes.dialogLabel, classes.flex)}>
										{this.state.dialogHeader}
									</Typography>
									<IconButton className={classes.secondaryIcon} onClick={this.handleClose} aria-label="Close">
										<CloseIcon />
									</IconButton>
								</Toolbar>
							</AppBar>
							<div className={classes.dialogContent}>
								{ (this.state.dialogContentType==='Filters') ?
									<FormControl
										component="fieldset"
										className={classes.dialogFormControl}>
										<FormLabel className={classNames(classes.dialogLabel, classes.offsetLabel)} component="legend">Vyberte kategorii zpráv</FormLabel>
										<List>
											{this.state.categories.map((item) => (
												<ListItem
													className={classes.listItem}
													key={item.value}
													role={undefined}
													dense
													button
													onClick={() => { this.setState({_category: item.value}) }}
												>
													<img src={item.image} alt={item.label} />
													<ListItemText primary={`${item.label}`} />
													<Radio
														checked={this.state._category ? this.state._category === item.value : this.state.category === item.value}
														onChange={this.handleChange}
														value={item.value}
														name="_category"
														className={classes.dialogRadio}
													/>
												</ListItem>
											))}
										</List>
										<Divider style={{margin: '12px 0 15px'}} />
										<MuiPickersUtilsProvider utils={CsLocalizedUtils} locale={csLocale}>
											<DatePicker
												disableToolbar
												TextFieldComponent={DatePickerInput}
												inputVariant="outlined"
												format="dd. MMMM yyyy"
												cancelLabel="Zrušit"
												okLabel="Potvrdit"
												showTodayButton={true}
												todayLabel="Dnes"
												disableFuture={true}
												value={this.state.date}
												onChange={this.selectDate}
											/>
										</MuiPickersUtilsProvider>
										<div className={classes.dialogFooter}>
											<Button onClick={this.handleSubmit} className={classes.dialogButton}>Zobrazit vyfiltrované výsledky</Button>
										</div>
									</FormControl>
									: <NewsFeed />
								}
							</div>
						</Dialog>
						<AppBar className={classes.appBar}>
							<Toolbar className={classes.toolBar}>
								{/* <IconButton onClick={() => this.toggleLeftDrawer(true)} className={classes.menuButton} color="inherit" aria-label="Menu">
								<MenuIcon
									className={classes.menuButton} />
								</IconButton> */}
								<div className={classes.logo}>
									<a href="/"><img src="images/logo.svg" alt="158.zone" /></a>
								</div>
								{/* <Button color="inherit">Login</Button> */}
							</Toolbar>
							<Divider />
							<Toolbar className={classNames(classes.secondaryToolbar, classes.toolBar)}>
								<TextField
									id="searchField"
									className={classes.UIField}
									variant="outlined"
									placeholder="Hledejte město nebo obec"
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Search color="secondary" />
											</InputAdornment>
										),
										classes: {
											root: classes.cssOutlinedInput,
											focused: classes.cssFocused,
											notchedOutline: classes.notchedOutline,
											input: classes.placeholder
										},
									}}
								/>
								<Hidden smDown>
									{categoriesSelect}
									{dateSelect}
									<Button
										id="newsFeedButton"
										onClick={this.toggleRightDrawer}
										className={classNames(classes.newsFeedButton, {[classes.newsFeedButtonPressed]: this.state.rightDrawerOpen})}
									>
										<img src="./images/newsFeed.png" alt="Zobrazit/Skrýt přehled zpráv" />
										{
											(this.state.rightDrawerOpen)
												? (<span>Skrýt přehled zpráv</span> )
												: (<span>Zobrazit přehled zpráv</span>)
										}
									</Button>
								</Hidden>
							</Toolbar>
							<Divider />
						</AppBar>
					</div>
					<div id="main"
						className={classNames(classes.content, {
							[classes.contentShift]: this.rightDrawerOpen,
						})}>
						<TheMap date={this.state.date} categoryFilter={this.state.category} ref={el => mapInstance = el } />
						<Route exact path="/" />
					</div>
					<Drawer
						variant="persistent"
						anchor="right"
						open={this.state.rightDrawerOpen}
						className={classes.drawer}
						classes={{
							paper: classes.drawerPaper,
						}}
					>
						<NewsFeed />
					</Drawer>
					<Hidden mdUp>
						<Fab onClick={this.showDialog('NewsFeed', 'Přehled zpráv')} aria-label="Edit" className={classes.newsFab}>
							<Icon className={classes.mobileIcon}><img src="./images/newsMobileIcon.png" alt="Přehled zpráv" /></Icon>
						</Fab>
						<Fab onClick={this.showDialog('Filters', 'Filter zpráv')} variant="extended" aria-label="Edit" className={classes.filterFab}>
							<Icon className={classes.mobileIcon}><img src="./images/filterMobile.png" alt="Edit" /></Icon>
						</Fab>
					</Hidden>
				</MuiThemeProvider>
			</div>
		</CssBaseline>
		);
	}
}

App.propTypes = {
	classes: PropTypes.object
}

export default withStyles(styles)(App);

export {mapInstance};
