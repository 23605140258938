
const Model = {
	getCzechDate: (timestamp) => {
		timestamp = parseInt(timestamp);
		const czMonths = ['leden', 'únor', 'březen', 'duben', 'květen', 'červen', 'červenec', 'srpen', 'září', 'říjen', 'listopad', 'prosinec'];
		const date = new Date(timestamp);
		return date.getDate() + '. ' + czMonths[date.getMonth()] + ' ' + date.getFullYear();
	}
}

export default Model
