import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './sass/index.scss';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';

var appInstance;

class Index extends Component {
	render() {
		return (
			<App ref={el => appInstance = el } />
		);
	}
}

export {appInstance};

ReactDOM.render(
	<Router>
		<Index />
	</Router>, document.getElementById('root'));
//registerServiceWorker();

require('./custom-js/script.js');
