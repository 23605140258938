import React, { Component } from 'react';
import {addMarkerDataCallback, /*addEventsCountPerRegion, addEventsCountPerDistrict,*/ m, sendDirectivesToReactInstance} from '../custom-js/script';
import PropTypes from 'prop-types';
import format from 'date-fns/format';

class TheMap extends Component {

	constructor(props) {
		super(props);

		this.state = {
			lat: null,
			long: null,
			zoom: null,
			categoryFilter: 'all',
			date: new Date(),
			geoLocation: false,
			init: true,
			geolocationZoomChange: false
		};
	}

	changeDirectives = (directives, geolocationChange, showRegions) => {
		let lat = directives.coords.y;
		let long = directives.coords.x;
		let zoom = directives.zoom;
		let categoryFilter = this.props.categoryFilter;
		let date = this.props.date;
		let init = (geolocationChange) ? true : false;
		this.setState({
			lat: lat,
			long: long,
			zoom: zoom,
			categoryFilter: categoryFilter,
			date: date,
			showRegions: showRegions,
			init: init
		});

		this.fetchData();
	}

	async fetchData () {
		let path = 'map/feed';
		let mapCallback = data => addMarkerDataCallback(data);
		/*if (this.state.showRegions === true) {
			if (this.state.zoom < 9) {
				path = 'map/regions-feed';
				mapCallback = data => addEventsCountPerRegion(data)
			}
			else if (this.state.zoom < 12) {
				path = 'count-districts-events';
				mapCallback = data => addEventsCountPerDistrict(data)
			}
		}*/

		let queryString = path
		+ '?lat=' + this.state.lat
		+ '&lng=' + this.state.long
		+ '&categoryId=' + this.props.categoryFilter
		+ '&date=' + format(this.props.date, 'yyyy-MM-dd')
		+ '&zoom=' + this.state.zoom
		+ '&init=' + this.state.init
		+ '&width=' + window.innerWidth
		+ '&height=' + (window.innerHeight - 112)
		+ '&geolocationZoomChange=' + this.state.geolocationZoomChange

		await fetch(process.env.REACT_APP_SERVICE_URL + '/' + queryString)
			.then((resp) => resp.json())
			.then(data => {
				if (data['zoom']) {
					if (data['zoom'] !== this.state.zoom) {
						m.setZoom(data['zoom']);
						this.setState({
							geolocationZoomChange: true
						});
						sendDirectivesToReactInstance(false, true);
					}
					else {
						mapCallback(data);
						this.setState({
							geolocationZoomChange: false
						});
					}
				}
				else {
					this.setState({
						geolocationZoomChange: false
					});
					mapCallback(data);
				}
			});

		this.setState({
			init: false
		});
	}

	componentDidUpdate = (prevProps) => {
		if (prevProps.categoryFilter !== this.props.categoryFilter) {
			if (m) {
				this.fetchData()
			}
		}
		if (prevProps.date !== this.props.date) {
			if (m) {
				this.fetchData()
			}
		}
	}

	render() {
		return (
			<div id="map" data-lat={this.state.lat} data-long={this.state.long} data-zoom={this.state.zoom} data-category-filter={this.props.categoryFilter}>
			</div>
		);
	}
}

TheMap.propTypes = {
	categoryFilter: PropTypes.string.isRequired
};

export default TheMap;
